<template>
  <div class="finish">
    <!-- <img src="../assets/image/baicheng/finish2.png" alt="" /> -->
    <div class="words">
      <p class="title">恭喜您完成全部测评</p>
      <el-button class="exit" type="primary" @click="toExit">退出</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    toExit() {
      sessionStorage.clear();
      this.$router.replace('/login');
    }
  },
  created() {
   
  },
};
</script>
<style lang="scss" scoped>
.finish {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  text-align: center;
  background: #ffffff;
  // img {
  //   width: 12rem;
  //   height: 12rem;
  //   margin-top: 6rem;
  //   margin-bottom: 2rem;
  // }
  .words {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -5rem;
    .title {
      font-weight: 550;
      font-size: 1.1rem;
      margin-bottom: 2rem;
    }
    .exit {
      width: 8rem;
    }
  }
}
</style>
